<template>
    <div class="top-entry">
      <div class="top-entry-item" @click="topTab(item,index)" v-for="(item,index) in topEntry" :key="index">
        <div class="top-entry-con flex">
          <div class="top-entry-item-text">{{item.name}}</div>
          <div class="item-line" v-if="tabIndex == index"></div>
        </div>
      </div>
    </div>
</template>


<script setup lang="ts">
import { ref } from 'vue'
import utils from './utils.ts'
const orn = $utils.getHost()
const route = useRoute()
// console.log("orn==========",orn);
const refIdValue = route.query.refId || route.query.refid || ''
const topEntry = ref([
{
    id: 0,
    name: utils.tc('b_home_top_flights'),
    url: `/${utils.curLang}/main-flights?refid=${refIdValue}`,

  }, {
    id: 1,
    name: utils.tc('b_home_top_hotel'),
    url: `/${utils.curLang}/main-hotels?refid=${refIdValue}`,
  }, {
    id: 2,
    name: utils.tc('b_home_top_trains'),
    url: `/${utils.curLang}/main-trains?refid=${refIdValue}`,
  }
])
const props = defineProps({
    tab: {
    type: String,
    default: "-99",
  },
})
const tabIndex = ref(props.tab)
// console.log("tabIndex.value========",tabIndex.value);
const topTab = (tab: any,index:Number) => {
  console.log(tab)
    tabIndex.value = index.toString();
    setTimeout(() => {
      window.location.href = tab.url;
    });

}
</script>


<style lang="scss" scoped>
.flex{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-entry{
    width: 1190px;
    margin: 0 auto;
    color:#fff;
    display: flex;
    flex-direction: row;
    margin-top:10px;
    margin-bottom: 100px;
  }
  .top-entry .top-entry-item{
    margin-right: 40px;
    font-size: 16px;
    cursor: pointer; /* 设置为可点击的小手图标 */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }
  .top-entry .top-entry-con{
    position: relative;
    padding-bottom: 6px;
  }
  .top-entry .top-entry-item-text{
    flex-shrink: 0;
    font-weight: 600;
  }
  .top-entry .top-entry-item:last-child{
    margin-right: 0;
  }
  .top-entry .top-entry-item:hover{
    background-color: rgba(255,255,255,.1);
    border-radius: 4px;
  }
  .top-entry .item-line{
    width: 24px;
    height: 2px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #FFF;
    // margin-top: 6px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform:translateX(-50%);
  }
</style>