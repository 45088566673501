import queryString from 'query-string'

/** M站默认refid */
export const DefaultRefid = '2000202122' as const

/**
 * 获取当前链接中的refid，没有则返回默认值
 *
 * @returns URL
 */
export function getRefidFromUrl() {
  const url = useRequestURL()
  /**
   * 从sessionStorage中获取outrefid， 由埋点sdk设置首次访问的refid为outrefid
   *
   * file.hopegoocdn.com/hopegoo/track/track-sdk/collect/0.0.7/index.global.js
 *  */
  const outrefid = typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('outrefid') : null
  const refid = url.searchParams.get('refid') || url.searchParams.get('refId')

  return refid || outrefid || DefaultRefid
}

/**
 * 在链接中添加refid, 如果链接有hash值，也会添加refid参数
 *
 * @param url
 * @param refid
 * @returns 返回添加了refid的链接
 */
export function jumpToRefidUrl(url: string, refid?: string) {
  if (!url) { return '' }

  if (/^\/\//.test(url)) {
    url = location.protocol + url
  }
  else if (/^\//.test(url)) {
    url = location.origin + url
  }

  const urlObj = new URL(url)
  const appendRefid = refid || getRefidFromUrl()
  urlObj.searchParams.set('refid', appendRefid)

  if (urlObj.hash) {
    const [prefix, hashValue] = urlObj.hash.split('?')
    const hash = queryString.parse(decodeURIComponent(hashValue || ''))
    hash.refid = appendRefid
    urlObj.hash = `${prefix}?${queryString.stringify(hash)}`
  }
  return urlObj.toString()
}
