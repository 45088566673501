<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import { trackEvent } from '~/utils/track'

const props = withDefaults(
  defineProps<{
    list: any[]
    tabName: string
  }>(),
  { list: () => [], tabName: '' },
)
const isLogin = useComCookie('sectoken').value
const { locale } = useI18n()
const { t } = useLang()

const GroupNumber = 3
const swiper = ref<any>(null)
const showPrevBtn = ref(false)
const showNextBtn = ref(true)

const listLeng = computed(() => props.list?.length)
const imgObj = computed(() => {
  if (listLeng.value === 1) {
    return props.list[0]
  }
  else {
    return null
  }
})
watch(() => props.tabName, () => {
  showPrevBtn.value = false
  showNextBtn.value = true
  swiper.value?.slideTo(0)
})
function prevPage() {
  swiper.value?.slidePrev()
}
function nextPage() {
  swiper.value?.slideNext()
}
// 设置swiper实例
function setThumbsSwiper(swiperIns: any) {
  swiper.value = swiperIns
}

function slidePrevTransitionEnd(swiper: any) {
  if (swiper.activeIndex === 0) {
    showPrevBtn.value = false
    showNextBtn.value = true
  }
  else {
    showPrevBtn.value = true
    showNextBtn.value = true
  }
  onSlideChange(swiper)

}
function slideNextTransitionEnd(swiper: any) {
  const maxIndex = listLeng.value - GroupNumber
  if (swiper.activeIndex === maxIndex) {
    showPrevBtn.value = true
    showNextBtn.value = false
  }
  else {
    showPrevBtn.value = true
    showNextBtn.value = true
  }
  onSlideChange(swiper)
  
}
function onSlideChange(swiper: any) {
  // Homepage-Operating首页运营位-banner浏览
  try {
    const index = swiper.activeIndex;
    trackEvent({
    eventid: 12199,
    label: 'banner',
    category: 'PC-homepage',
    action: 'view',
    value: {
      "operatingId": props.list[index].configId,
      "operatingurl": props.list[index].linkUrl,
      "operatingSort": props.list[index].priority
    },
  })
  } catch(e) {}
}

function onClickBanner(item: any, index: number) {
  // Homepage-Operating首页运营位-banner点击
  trackEvent({
    eventid: 12201,
    label: 'banner',
    category: 'PC-homepage',
    action: 'click',
    value: {
      "operatingId": props.list[index].configId,
      "operatingurl": props.list[index].linkUrl,
      "operatingSort": props.list[index].priority
    },
  })
  if (item.linkUrl) {
    // $utils.sendEvent(
    //   '',
    //   '点击',
    //   'Banner',
    //   `^${props.tabName}^${locale.value}^Banner:[${index}]^`,
    // )
    const tid = setTimeout(() => {
      clearTimeout(tid)
      window.open(item.linkUrl)
    })
  }
}
</script>

<template>
  <div class="cpt-business-banner">
    <!-- 单图模式 -->
    <div v-if="listLeng === 1" class="img-wrapper">
      <img
        :src="imgObj.imgUrl"
        :class="!imgObj.linkUrl ? 'no-pointer' : ''"
        alt=""
        @click="onClickBanner(imgObj, 0)"
      >
      <div v-if="imgObj.adv" class="ads">
        {{ t('home.f_ads') }}
      </div>
    </div>

    <!-- 多图模式 -->
    <Swiper
      v-if="listLeng > 1"
      :slides-per-view="GroupNumber"
      :space-between="20"
      :class="{ 'is-center': listLeng < GroupNumber }"
      @swiper="setThumbsSwiper"
      @slidePrevTransitionEnd="slidePrevTransitionEnd"
      @slideNextTransitionEnd="slideNextTransitionEnd"
    >
      <SwiperSlide
        v-for="(item, index) in list"
        :key="index"
        :class="[listLeng === 2 ? 'twoBanner' : 'moreBanner']"
      >
        <img
          :src="item.imgUrl"
          :class="!item.linkUrl ? 'no-pointer' : ''"
          alt=""
          @click="onClickBanner(item, index)"
        >
        <div v-if="item.adv" class="ads">
          {{ t('home.f_ads') }}
        </div>
      </SwiperSlide>
    </Swiper>
    <div v-if="listLeng > GroupNumber" class="res_btn_box">
      <div v-show="showPrevBtn" class="res_btn btn_prev" @click="prevPage" />
      <div v-show="showNextBtn" class="res_btn btn_next" @click="nextPage" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.cpt-business-banner {
  width: 1190px;
  height: 160px;
  margin: 30px auto;
  color: #fff;
}

:deep(.swiper) {
  width: 100%;
  height: 100%;

  // &.is-center {
  //   .swiper-wrapper {
  //     justify-content: center;
  //   }
  // }
}

.swiper-slide,
.img-wrapper {
  display: flex;
  text-align: center;
  font-size: 18px;
  background: #f3f3f3;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 585px !important;
  border-radius: 8px;
  img {
    border-radius: 8px;
  }
}
.ads {
  border-radius: 0 0 0 8px;
  background: linear-gradient(90deg, #0000004d 0%, #00000000 104.17%);
  // width: 24px;
  height: 12px;
  position: absolute;
  color: #fff;
  font-size: 8px;
  left: 0px;
  bottom: 0px;
  padding: 0 4px;
}

.img-wrapper {
  width: 100% !important;
}

.twoBanner {
  width: 585px !important;
}

.moreBanner {
  width: 383px !important;
}

// .marginRight20 {
//   margin-right: 20px!important;
// }

.img-wrapper {
  height: 100%;
}

.swiper-slide img,
.img-wrapper img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.res_btn_box {
  position: relative;
}

.res_btn {
  position: absolute;
  top: -105px;
  width: 50px;
  height: 50px;
  z-index: 5;
  background: #ffffff;
  background-position: 0px -452px;
  background-image: url('https://pic5.hopegoocdn.com/i/ori/1xjEJpOQwk8.png') !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  stroke: #e4e4e4;
  filter: drop-shadow(0 4px 16px #0000001a);
  cursor: pointer;
}

.res_btn:hover {
  background-color: var(--Brand-Green);
}

.btn_prev {
  left: -25px;
  transform: rotate(180deg);
  &:hover {
    background-position: 0px -503px;
  }
}

.btn_next {
  right: -25px;
  &:hover {
    background-position: 0px -503px;
  }
}
.no-pointer {
  cursor: default !important;
}
</style>
